<template>
  <div class="inheritRateReferenceLayerPopup">
    <div class="content">
      <h2>2022년 상속세율 기준표</h2>

      <table class="referenceTable">
        <thead>
          <tr>
            <th>과세표준</th>
            <th>세율</th>
            <th>누진공제액</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1억원 이하</td>
            <td>10%</td>
            <td>-</td>
          </tr>
          <tr>
            <td>1억원 초과 ~ 5억원 이하</td>
            <td>20%</td>
            <td>1,000만원 </td>
          </tr>
          <tr>
            <td>5억원 초과 ~ 10억원 이하</td>
            <td>30%</td>
            <td>6,000만원 </td>
          </tr>
          <tr>
            <td>10억원 초과 ~ 30억원 이하 </td>
            <td>40%</td>
            <td>1억6,000만원</td>
          </tr>
          <tr>
            <td>30억원 초과</td>
            <td>50%</td>
            <td>4억6,000만원</td>
          </tr>
        </tbody>
      </table>

      <div class="dataSources">
        자료 : 국세청
      </div>
    </div>
    <button
      class="closeLayerPopupBtn"
      @click="closeInheritReferenceLayerPopup"
    >
      <img src="@/assets/img/common/closeLayerPopupBtn.png" alt="">
    </button>
  </div>
</template>
<script>
export default {
  name: 'InheritRateReferenceLayerPopup',
  methods: {
    closeInheritReferenceLayerPopup () {
      this.$emit('inheritReferenceLayerPopup', false)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/inheritRateReferenceLayerPopup";
</style>

