<template>
  <div style="width: 100%; height: 100%;">
    <CommonHeader v-if="viewMode === PAGE_MODE.VIEW"/>
    <div
      class="reportContainer"
      :class="pageIdx === 0 ? 'reportCoverMode' : ''"
      :style="printWidth>0?`width:${printWidth}px;`:''"
      ref="printPage"
    >
      <div
        class="reportCover"
        :style="printWidth>0?`width:${printWidth}px;height:${printHeight}px`:''"
        ref="printItem0"
        @click="onClickRemoveCover"
        v-if="viewMode === PAGE_MODE.COVER || viewMode === PAGE_MODE.PRINT"
      >
        <img src="@/assets/img/report/reportCover2.jpg" alt="">
      </div>
      <NowInheritAssets
        :userInfo="userInfo"
        :style="printWidth>0?`width:${printWidth}px;height:${printHeight}px`:''"
        v-if="(viewMode === PAGE_MODE.VIEW && pageIdx === 1) || viewMode === PAGE_MODE.PRINT"
        @inheritReferenceLayerPopup="isInheritReferenceLayerPopup"
      />
      <InheritTimingAssets
        :userInfo="userInfo"
        :style="printWidth>0?`width:${printWidth}px;height:${printHeight}px`:''"
        v-if="(viewMode === PAGE_MODE.VIEW && pageIdx === 2) || viewMode === PAGE_MODE.PRINT"
        @inheritReferenceLayerPopup="isInheritReferenceLayerPopup"
      />
      <InheritTaxDetail
        :style="printWidth>0?`width:${printWidth}px;height:${printHeight}px`:''"
        v-if="(viewMode === PAGE_MODE.VIEW && pageIdx === 3) || viewMode === PAGE_MODE.PRINT"
      />
      <FirstInheritEstimate
        :style="printWidth>0?`width:${printWidth}px;height:${printHeight}px`:''"
        v-if="(viewMode === PAGE_MODE.VIEW && pageIdx === 4) || viewMode === PAGE_MODE.PRINT"
      />
      <SecondInheritEstimate
        :style="printWidth>0?`width:${printWidth}px;height:${printHeight}px`:''"
        v-if="((viewMode === PAGE_MODE.VIEW && pageIdx === 5) || viewMode === PAGE_MODE.PRINT) && visibleSecondInheritance"
      />
      <AnalysisInheritPayment
        :viewMode="viewMode"
        :style="printWidth>0?`width:${printWidth}px;height:${printHeight}px`:''"
        v-if="(viewMode === PAGE_MODE.VIEW && pageIdx === 6) || viewMode === PAGE_MODE.PRINT"
      />
    </div>
    <div class="optionBox" v-if="viewMode === PAGE_MODE.VIEW">
      <div class="optionLeft">
        <button
            class="goInputPage"
            @click="goToInputPage"
            v-if="pageIdx === 1"
        >
          <img src="@/assets/img/report/icoInputPage.png" alt="">
          입력화면으로
        </button>
        <button
            class="goPrevPage"
            @click="goPrevPage"
            v-if="pageIdx > 1"
        >
          <img src="@/assets/img/report/icoPrevArrow.png" alt="">
          이전화면으로
        </button>
        <button
            class="savePDF "
            @click="savePDF"
        >
          <img src="@/assets/img/report/icoSavePDF.png" alt="">
          리포트 저장
        </button>
        <div
          v-if="pageIdx===6"
          class="noticeComment asterisk"
        >
          회사는 상품에 대하여 충분히 설명할 의무가 있으며, 고객님께서는 이에 대한 설명을 들을 의무가 있습니다.<br/>
          상품 가입시 자세한 사항은 상품설명서 및 약관을 참조하시기 바랍니다.
        </div>
      </div>
      <div class="optionRight">
        <button
            class="goNextPage"
            @click="goNextPage"
            v-if="pageIdx < 6"
        >
          다음으로
          <img src="@/assets/img/report/icoNextArrow.png" alt="">
        </button>
      </div>
    </div>
    <div class="loading" v-show="isLoading">
      <div>
        <img src="@/assets/img/report/loading.svg" alt="">
        <p>PDF 처리중 입니다.</p>
      </div>

    </div>
    <div
      class="dimm"
      v-if="visibleInheritReferenceLayerPopup"
    >
      <InheritRateReferenceLayerPopup
        @inheritReferenceLayerPopup="isInheritReferenceLayerPopup"
      />
    </div>

  </div>
</template>
<script>
import html2pdf from 'html2pdf.js'
import CommonHeader from "@/components/inheritInput/layout/CommonHeader";
import NowInheritAssets from '@/components/reportPage/NowInheritAssets.vue'
import InheritTimingAssets from "@/components/reportPage/InheritTimingAssets";
import InheritTaxDetail from "@/components/reportPage/InheritTaxDetail";
import FirstInheritEstimate from "@/components/reportPage/FirstInheritEstimate";
import SecondInheritEstimate from "@/components/reportPage/SecondInheritEstimate";
import AnalysisInheritPayment from "@/components/reportPage/AnalysisInheritPayment";
import InheritRateReferenceLayerPopup from "@/components/reportPage/layerPopup/InheritRateReferenceLayerPopup"
import PAGE_MODE from "@/script/Constant"
import GetUserInfo from "../utils/GetUserInfo";

export default {
  name: 'ReportPage',
  components: {
    CommonHeader,
    NowInheritAssets,
    InheritTimingAssets,
    InheritTaxDetail,
    FirstInheritEstimate,
    SecondInheritEstimate,
    AnalysisInheritPayment,
    InheritRateReferenceLayerPopup
  },
  data() {
    return {
      viewMode: PAGE_MODE.PAGE_MODE.COVER,
      pageIdx: 0,
      prevPageIdx: -1,
      pdfWorker: null,
      printWidth: 0,
      printHeight: 0,
      isLoading:false,
      visibleInheritReferenceLayerPopup: false,
      coverTimer: null
    }
  },
  mounted() {
    this.startCover();
    this.checkAddMobileView;
    window.addEventListener("resize", this.checkAddMobileView)
  },
  beforeDestroy() {
    this.checkRemoveMobileView;
    window.addEventListener("resize", this.checkRemoveMobileView)
  },
  watch: {
    pageIdx(idx) {
      if (idx < 100) {
        this.viewMode = PAGE_MODE.PAGE_MODE.VIEW
      } else if (idx === 999) {
        this.viewMode = PAGE_MODE.PAGE_MODE.PRINT
      } else {
        this.viewMode = PAGE_MODE.PAGE_MODE.COVER
      }
    }
  },
  computed: {
    userInfo: {
      get() {
        return GetUserInfo.getId()
      }
    },
    PAGE_MODE() {
      return PAGE_MODE.PAGE_MODE;
    },
    visibleSecondInheritance: {
      get() { return this.$store.getters.isVisibleSecondInheritanceTaxReport }
    }
  },
  methods: {
    startCover() {
      this.trackEnterReportPage()
      this.coverTimer = setTimeout(() => {
        this.onClickCover()
      },3000)
    },
    onClickRemoveCover() {
      if (this.coverTimer) {
        clearTimeout(this.coverTimer)
        this.onClickCover()
      }
    },
    onClickCover() {
      this.viewMode = PAGE_MODE.PAGE_MODE.VIEW
      this.pageIdx = 1
    },
    goToInputPage() {
      this.$router.go(-1)
      // this.$router.push({ path: '/inheritForm' })
    },
    goPrevPage() {
      if(this.pageIdx === 6) {
        if (!this.visibleSecondInheritance) {
          this.pageIdx = 4;
        } else {
          this.pageIdx -= 1;
        }
      }
       else {
        this.pageIdx -= 1;
      }
    },
    goNextPage() {
      if(this.pageIdx === 4) {
        if (!this.visibleSecondInheritance) {
          this.pageIdx = 6;
        } else {
          this.pageIdx += 1;
        }
      } else {
        this.pageIdx += 1;
      }
    },
    savePDF() {
      let today = new Date();
      let year = today.getFullYear();
      let month = ('0' + (today.getMonth() + 1)).slice(-2);
      let day = ('0' + today.getDate()).slice(-2);
      let dateString = year + month + day;

      this.prevPageIdx = this.pageIdx
      this.pageIdx = 999;

      this.isLoading = true





      setTimeout(() => {
        this.$nextTick(() => {
          const width = 1194
          const height = 758
          // const height = Math.floor(1194 / 1.5731)

          // document.querySelectorAll('.reportPage').forEach(d => {
          //   d.classList.add('print')
          // })

          const pdfOpt = {
            margin: 0,
            filename: `${dateString}_상속진단리포트.pdf`,
            image: {
              type: "jpeg",
              quality: 0.95
            },
            html2canvas: {
              scrollY: 0,
              scale: 1.5,
            },
            jsPDF: {
              unit: 'px',
              format: [height, width],
              orientation: 'landscape',
              compress: true
            }
          }
          this.pdfWorker = html2pdf().set(pdfOpt)
          window.pdfWorker = this.pdfWorker

          this.printWidth = width
          this.printHeight = height

          this.$nextTick(() => {
            const printPage = this.$refs.printPage

            // console.log(this.pdfWorker.from(printPage).get('canvas').toCanvas())
            this.pdfWorker.from(printPage).save().outputPdf().then(() => {
              this.printWidth = 0
              this.printHeight = 0
              this.isLoading = false
              this.pageIdx = this.prevPageIdx
            })
          })
        })
      }, 500)

      this.trackSavePDF()
    },
    isInheritReferenceLayerPopup(value){
      this.visibleInheritReferenceLayerPopup = value
    },
    trackEnterReportPage() {
      const parameterUser = {'employeeNumber': this.userInfo}
      window.gtag('event', 'Page_report', parameterUser)
    },
    trackSavePDF() {
      const parameterUser = {'employeeNumber': this.userInfo}
      window.gtag('event', 'Action_savePDF', parameterUser)
    },
    checkAddMobileView() {
      if (window.innerWidth < 768) {
        document.querySelector('#app').classList.add('mobileView')
      }
    },
    checkRemoveMobileView() {
      if (window.innerWidth < 768) {
        document.querySelector('#app').classList.remove('mobileView')
      }
    }
  }
}
</script>
