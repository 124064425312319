<template>
  <div class="reportPage firstInheritEstimate">
    <section>
      <div class="reportTitle">
        <h2>2차 상속세 예상 추이</h2>
      </div>

      <div class="reportEstimateData">
        <div class="estimateTable">
          <table class="nowInheritTax">
            <tbody>
              <tr>
                <th colspan="5">
                  <div class="inheritTax">
                    <p>1차 상속 시점 상속 과세액 <span>({{ report5.expectationYearsAge - report5.expectationYearsLater }} 세)</span></p>
                    <b>{{ $comma(report5.nowSecondInheritanceTax.inheritanceAmount) }}만원</b>
                  </div>
                </th>
              </tr>
              <tr>
                <td>총 상속세</td>
                <td class="borderNone"></td>
                <td>{{ $comma(report5.nowSecondInheritanceTax.finalTaxAmount) }}만원</td>
                <td class="borderNone"></td>
                <td>{{ toFixed((report5.nowSecondInheritanceTax.finalTaxAmount / report5.nowSecondInheritanceTax.inheritanceAmount) * 100, 2) }}%</td>
              </tr>
              <tr>
                <td>세후 재산 총액</td>
                <td class="borderNone"></td>
                <td>{{ $comma(report5.nowSecondInheritanceTax.inheritanceAmountAfterPayTax) }}만원</td>
                <td class="borderNone"></td>
                <td>{{ toFixed((report5.nowSecondInheritanceTax.inheritanceAmountAfterPayTax / report5.nowSecondInheritanceTax.inheritanceAmount) * 100, 2) }}% </td>
              </tr>
            </tbody>
          </table>

          <div class="later">
            {{ report5.expectationYearsLater }} 년 후
          </div>

          <table class="estimatedInheritTax">
            <tbody>
              <tr>
                <th colspan="5">
                  <div class="inheritTax">
                    <p>2차 상속 시점 상속 과세액 <span>({{ report5.expectationYearsAge }} 세)</span></p>
                    <b>{{ $comma(report5.secondInheritanceTaxExpectationYearsLater.inheritanceAmount) }}만원</b>
                  </div>
                </th>
              </tr>
              <tr>
                <td>총 상속세</td>
                <td class="borderNone"></td>
                <td>{{ $comma(report5.secondInheritanceTaxExpectationYearsLater.finalTaxAmount) }}만원</td>
                <td class="borderNone"></td>
                <td>{{ toFixed((report5.secondInheritanceTaxExpectationYearsLater.finalTaxAmount / report5.secondInheritanceTaxExpectationYearsLater.inheritanceAmount) * 100, 2) }}%</td>
              </tr>
              <tr>
                <td>세후 재산 총액</td>
                <td class="borderNone"></td>
                <td>{{ $comma(report5.secondInheritanceTaxExpectationYearsLater.inheritanceAmountAfterPayTax) }}만원</td>
                <td class="borderNone"></td>
                <td>{{ toFixed((report5.secondInheritanceTaxExpectationYearsLater.inheritanceAmountAfterPayTax / report5.secondInheritanceTaxExpectationYearsLater.inheritanceAmount) * 100, 2) }}%</td>
              </tr>
            </tbody>
          </table>

          <div class="notice">*10년 이내 단기재상속 세액공제는 반영되지 않음</div>
        </div>
        <div class="estimateGraph">
          <div class="verticalChartBox">
            <div class="chartInfo">
              <ul>
                <li><span/> 상속세</li>
                <li><span/> 세후 상속재산</li>
              </ul>
            </div>
            <div class="chartBox">
              <div class="default">배우자 연령</div>
              <div class="chartInner">
                <div class="inheritAge">
                  <div
                      class="dottedLine"
                      :style="`left: ${ageLine}%`"
                  />
                  <div
                      class="inheritAgeInfo"
                      :style="`left: ${ageLine}%`"
                  >
                    예상 2차 상속시점<br/>
                    (배우자 {{ report5.secondInheritanceTaxExpectationYearsLater.age}}세)
                    <span></span>
                  </div>
                </div>
                <ul class="axis_x">
                  <li
                      class="item"
                      v-for="(value, valueIdx) in report5.secondInheritanceTaxList"
                      :key="valueIdx"
                      :style="`
                      height:${ 50 + liHeight(value.inheritanceAmount, report5.secondInheritanceTaxList[report5.secondInheritanceTaxList.length - 1].inheritanceAmount) }%;
                      `"
                  >
                    <div class="text_box">
                      <span class="axisTitle">{{value.age}}세</span>
                    </div>
                    <div class="graph">
                    <span
                        class="bar data1"
                        :style="`height:${ toFixed(value.inheritanceAmountAfterPayTax / value.inheritanceAmount * 100, 1) }%;`"
                    >
                      <b>
                        {{ $comma(value.inheritanceAmountAfterPayTax) }} 만원<br/>
                        <b>{{ toFixed(value.inheritanceAmountAfterPayTax / value.inheritanceAmount * 100, 1) }}%</b>
                      </b>
                    </span>
                    <span
                        class="bar data2"
                        :style="`height:${ toFixed((value.finalTaxAmount / value.inheritanceAmount) * 100, 1) }%;`"
                    >
                      <b>{{ $comma(value.finalTaxAmount) }} 만원</b>
                    </span>
                      <span class="total">
                      총 과세액 <br/>
                      {{ $comma(value.inheritanceAmount) }}만원
                    </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "FirstInheritEstimate",
  computed: {
    report5: {
      get() {
        return this.$store.getters.getReport5
      }
    },
    ageLine() {
      return (1 - (100 - this.report5.secondInheritanceTaxExpectationYearsLater.age) / (100 - this.report5.nowSecondInheritanceTax.age)) * 100
    }
  },
  methods:{
    toFixed(v, n) {
      return parseFloat(v.toFixed(n))
    },
    liHeight(inheritanceAmount, maxInheritanceAmount) {
      if (inheritanceAmount <= 0) {
        return 0
      } else {
        return 50 * this.toFixed(inheritanceAmount / maxInheritanceAmount, 1)
      }
    }
  },
  mounted() {
    // console.log("report5 ::: ", this.report5)
  }
}
</script>
